
import Vue from "vue";

export default Vue.extend({
  name: "UserHelp",

  data: () => ({
    selectedTab: 0 as number,
    windowSize: null as any
  }),

  computed: {
    services() {
      return [
        {
          alias: "aaij",
          text: this.$t("service_types.aaij"),
          icon: "mdi-scale-balance",
          items: [
            {
              text: this.$t("user_help.register"),
              icon: "mdi-account-plus",
              link: "https://www.youtube.com/embed/qoIbJ2SNcKc"
            },
            {
              text: this.$t("user_help.apply"),
              icon: "mdi-file-document-edit",
              link: "https://www.youtube.com/embed/ak3htnMSsLw"
            },
            {
              text: this.$t("user_help.payment"),
              icon: "mdi-wallet",
              link: "https://www.youtube.com/embed/U9yt35y3uJE"
            },
            {
              text: this.$t("user_help.release"),
              icon: "mdi-text-box-check",
              link: "https://www.youtube.com/embed/OzA15QAsu9o"
            }
          ]
        },
        {
          alias: "exp",
          text: this.$t("service_types.exp"),
          icon: "mdi-magnify",
          items: [
            {
              text: this.$t("user_help.register"),
              icon: "mdi-account-plus",
              link: "https://www.youtube.com/embed/qoIbJ2SNcKc"
            },
            {
              text: this.$t("user_help.apply"),
              icon: "mdi-file-document-edit",
              link: "https://www.youtube.com/embed/ak3htnMSsLw"
            },
            {
              text: this.$t("user_help.payment"),
              icon: "mdi-wallet",
              link: "https://www.youtube.com/embed/U9yt35y3uJE"
            },
            {
              text: this.$t("user_help.release"),
              icon: "mdi-text-box-check",
              link: "https://www.youtube.com/embed/OzA15QAsu9o"
            }
          ]
        },
        {
          alias: "eval",
          text: this.$t("service_types.eval"),
          icon: "mdi-calculator",
          items: [
            {
              text: this.$t("user_help.register"),
              icon: "mdi-account-plus",
              link: "https://www.youtube.com/embed/qoIbJ2SNcKc"
            },
            {
              text: this.$t("user_help.apply"),
              icon: "mdi-file-document-edit",
              link: "https://www.youtube.com/embed/ak3htnMSsLw"
            },
            {
              text: this.$t("user_help.payment"),
              icon: "mdi-wallet",
              link: "https://www.youtube.com/embed/U9yt35y3uJE"
            },
            {
              text: this.$t("user_help.release"),
              icon: "mdi-text-box-check",
              link: "https://www.youtube.com/embed/OzA15QAsu9o"
            }
          ]
        },
        {
          alias: "cert",
          text: this.$t("service_types.cert"),
          icon: "mdi-script-text",
          items: [
            {
              text: this.$t("user_help.register"),
              icon: "mdi-account-plus",
              link: "https://www.youtube.com/embed/qoIbJ2SNcKc"
            },
            {
              text: this.$t("user_help.apply"),
              icon: "mdi-file-document-edit",
              link: "https://www.youtube.com/embed/agZZ8-b-rFg"
            },
            {
              text: this.$t("user_help.payment"),
              icon: "mdi-wallet",
              link: "https://www.youtube.com/embed/oA3P8YSk3ro"
            },
            {
              text: this.$t("user_help.release"),
              icon: "mdi-text-box-check",
              link: "https://www.youtube.com/embed/Uu7tyZaLXRo"
            },
            {
              text: this.$t("user_help.verification"),
              icon: "mdi-account-check",
              link: "https://www.youtube.com/embed/mJn2pn4plDk"
            }
          ]
        },
        {
          alias: "ata",
          text: this.$t("service_types.ata"),
          icon: "mdi-briefcase",
          items: [
            {
              text: this.$t("user_help.register"),
              icon: "mdi-account-plus",
              link: "https://www.youtube.com/embed/qoIbJ2SNcKc"
            },
            {
              text: this.$t("user_help.apply"),
              icon: "mdi-file-document-edit",
              link: "https://www.youtube.com/embed/putbMw_sm_M"
            },
            {
              text: this.$t("user_help.payment"),
              icon: "mdi-wallet",
              link: "https://www.youtube.com/embed/-XD-FO4Rp5M"
            },
            {
              text: this.$t("user_help.release_ata"),
              icon: "mdi-text-box-check",
              link: "https://www.youtube.com/embed/N2fF3gg5rqw"
            }
          ]
        }
      ] as Array<any>;
    },
    verticalTabs() {
      return this.windowSize > 900;
    }
  },

  methods: {
    onResize(): void {
      this.windowSize = window.innerWidth;
    }
  }
});
